/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { getRequest, delRequest } from "../../../axiosRoutes/index";
import {
  Button,
  Box,
  CircularProgress,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
  TableRow,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@mui/material";
import SideNavBar from "../sidebar/SideNav";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../../axiosRoutes/toastConfig";
import AddLeave from "./AddLeave";

const AdminLeaves = () => {
  const [leaveType, setLeaveType] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [leaves, setLeaves] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [updateId, setUpdateId] = useState(null);

  const fetchLeaveType = async () => {
    try {
      const response = await getRequest("filter/leave");
      const statusArray = Object.keys(response).map((key) => ({
        label: key,
        value: response[key],
      }));
      setLeaveType(statusArray);
    } catch (error) {
      console.error("Error fetching type:", error);
    }
  };

  const fetchStatus = async () => {
    try {
      const response = await getRequest("filter/status");
      const statusArray = Object.keys(response).map((key) => ({
        label: key,
        value: response[key],
      }));
      setStatuses(statusArray);
    } catch (error) {
      console.error("Error fetching Filter:", error);
    }
  };

  const fetchLeaves = async () => {
    const access = sessionStorage.getItem("access");
    try {
      const response = await getRequest(`employee/leave`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      const formattedLeaves = response.map((leave) => {
        if (leave) {
          const startDate = new Date(leave.startDate);
          leave.startDate = startDate.toLocaleDateString("en-GB");
          const endDate = new Date(leave.endDate);
          leave.endDate = endDate.toLocaleDateString("en-GB");
        }
        const typeObj = leaveType.find((type) => type.value === leave.type);
        leave.type = typeObj ? typeObj.label : leave.type;
        const statusObj = statuses.find(
          (status) => status.value === leave.status
        );
        leave.status = statusObj ? statusObj.label : leave.status;
        return leave;
      });
      setLeaves(formattedLeaves);
    } catch (error) {
      console.error("Error fetching Leaves:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchLeaveType();
      await fetchStatus();
      fetchLeaves();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (leaveType.length > 0 && statuses.length > 0) {
      fetchLeaves();
    }
  }, [leaveType, statuses]);

  const handleDelete = async () => {
    try {
      const access = sessionStorage.getItem("access");
      const response = await delRequest(`employee/leave/${deleteId}`, access);
      fetchLeaves();
      handleClose();
      toast.success(response, toastConfig);
    } catch (error) {
      toast.error(error.message, toastConfig);
    }
  };

  const handleClickOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const selectLeave = (id) => {
    setUpdateId(id);
    handleOpen();
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpen(false);
    setDeleteId(null);
    setUpdateId(null);
    fetchLeaves();
  };

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FF5722" }} />
        <Typography variant="h6" sx={{ marginTop: "18px", color: "#fff" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <div className="app-container" style={{ color: "#fff", display: "flex" }}>
      <ToastContainer />
      <SideNavBar />
      <div style={{ flex: 1, margin: "0.5rem" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          padding="1rem"
        >
          <Grid item>
            <h1>Leaves</h1>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{
                backgroundColor: "#fff",
                color: "black",
                "&:hover": {
                  backgroundColor: "#e1e1e1",
                },
              }}
            >
              Add Leave
            </Button>
          </Grid>
        </Grid>
        {leaves.length === 0 ? (
          <Typography variant="h6" sx={{ color: "#fff", textAlign: "center" }}>
            No Data Yet
          </Typography>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Reason
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Comments
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Duration
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Start Date
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    End Date
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leaves.map((leave, id) => (
                  <TableRow key={id}>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {id + 1}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {leave.type}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {leave.reason}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {leave.comments || "N/A"}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {leave.duration}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {leave.startDate}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {leave.endDate}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {leave.status}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor:
                            leave.status === "Approved" ? "#86c886" : "#4CAF50",
                          margin: 2,
                        }}
                        disabled={leave.status === "Approved"}
                        aria-label="edit"
                        size="small"
                        onClick={() => selectLeave(leave._id)}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor:
                            leave.status === "Approved" ? "#ff8575" : "#F44336",
                          margin: 2,
                        }}
                        disabled={leave.status === "Approved"}
                        aria-label="delete"
                        size="small"
                        onClick={() => handleClickOpen(leave._id)}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Dialog
          open={open}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#212121",
              color: "#fff",
              padding: "15px",
            },
            "& .MuiDialogTitle-root": {
              padding: "5px",
            },
            "& .MuiDialogContentText-root": {
              color: "#fff",
            },
            "& .MuiDialogContent-root": {
              padding: "5px",
            },
            "& .MuiDialogActions-root": {
              padding: "5px",
            },
          }}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are You Sure You Want to Delete this Leave Application?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                backgroundColor: "#A6A6A6",
                color: "#fff",
                "&:hover": { backgroundColor: "#B8B8B8" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              sx={{
                backgroundColor: "#E5622D",
                color: "#fff",
                "&:hover": { backgroundColor: "#D0521B" },
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <AddLeave
          open={openModal}
          leaveId={updateId}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
};

export default AdminLeaves;
