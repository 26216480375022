import React, { useState, useEffect } from "react";
import { getRequest, delRequest } from "../../axiosRoutes/index";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
  Radio,
  FormControlLabel,
  RadioGroup,
  Slider,
  FormControl,
  Button,
  InputBase,
  InputAdornment,
  Box,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
} from "@mui/material";
import SideNavBar from "../sidebar/SideNav";
import AddEmployee from "./AddEmployee";

import { Search as SearchIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArchiveIcon from "@mui/icons-material/Archive";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../axiosRoutes/toastConfig";

const Employees = () => {
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [updateId, setUpdateId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [statuses, setStatuses] = useState([]);
  const [statusFilter, setStatusFilter] = useState("active");
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [salaryRange, setSalaryRange] = useState([10000, 500000]);

  const fetchEmployees = async () => {
    const access = sessionStorage.getItem("access");
    const queryParams = new URLSearchParams();
    if (statusFilter) queryParams.append("status", statusFilter);
    if (searchQuery) queryParams.append("search", searchQuery);
    if (salaryRange[0]) queryParams.append("minSalary", salaryRange[0]);
    if (salaryRange[1]) queryParams.append("maxSalary", salaryRange[1]);
    const url = `employee?${queryParams.toString()}`;
    try {
      const response = await getRequest(url, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      setEmployees(response);
    } catch (error) {
      console.error("Error fetching employees:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStatus = async () => {
    try {
      const response = await getRequest("filter/active");
      const statusArray = Object.keys(response).map((key) => ({
        label: key,
        value: response[key],
      }));
      setStatuses(statusArray);
    } catch (error) {
      console.error("Error fetching Filter:", error);
    }
  };

  useEffect(() => {
    fetchEmployees();
    fetchStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleSliderChange = (event, newValue) => {
    setSalaryRange(newValue);
  };

  const formatLabel = (value) => {
    return `${value.toLocaleString()} PKR`;
  };

  const handleDelete = async () => {
    try {
      const access = sessionStorage.getItem("access");
      const response = await delRequest(`employee/${deleteId}`, access);
      fetchEmployees();
      handleClose();
      toast.success(response, toastConfig);
    } catch (error) {
      toast.error(error.message, toastConfig);
    }
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClickOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const profile = (id, user) => {
    navigate("/employee/profile", { state: { id, user } });
  };

  const selectEmployee = (id) => {
    setUpdateId(id);
    handleOpen();
  };

  useEffect(() => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (searchQuery.length >= 3 || searchQuery === "") {
      const timeout = setTimeout(() => {
        fetchEmployees();
      }, 300);

      setTypingTimeout(timeout);

      return () => clearTimeout(timeout);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleClose = () => {
    setOpenModal(false);
    setOpen(false);
    setOpenFilter(false);
    setDeleteId(null);
    setUpdateId(null);
    fetchEmployees();
  };

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FF5722" }} />
        <Typography variant="h6" sx={{ marginTop: "18px", color: "#fff" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <div className="app-container" style={{ color: "#fff", display: "flex" }}>
      <ToastContainer />
      <SideNavBar />
      <div style={{ flex: 1, margin: "0.5rem" }}>
        <h1 style={{ padding: " 1rem 1rem 0 1rem", marginBottom: 0 }}>
          Employees
        </h1>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          padding="0.5rem 1rem 1rem 1rem"
        >
          <Grid item>
            <Paper
              style={{
                background: "#000",
                padding: "2px 4px",
                margin: "10px auto",
                borderBottom: "1px solid white",
                borderRadius: "0",
              }}
            >
              <InputBase
                style={{ marginLeft: 8, flex: 1, color: "#fff" }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#fff" }} />
                  </InputAdornment>
                }
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Paper>
          </Grid>
          <Grid item>
            <IconButton
              style={{
                color: "#fff",
                margin: 2,
              }}
              aria-label="view"
              size="medium"
              onClick={() => setOpenFilter(true)}
            >
              <FilterAltOutlinedIcon fontSize="inherit" />
            </IconButton>
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{
                marginLeft: "1rem",
                backgroundColor: "#fff",
                color: "black",
                "&:hover": {
                  backgroundColor: "#e1e1e1",
                },
              }}
            >
              Create
            </Button>
          </Grid>
        </Grid>
        {employees.length === 0 ? (
          <Typography variant="h6" sx={{ color: "#fff", textAlign: "center" }}>
            No Data Yet
          </Typography>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Designation
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Department
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    CNIC
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Phone
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Salary
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employees.map((employee, id) => (
                  <TableRow key={id}>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {id + 1}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {employee.name}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {employee.userId.email}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {employee.designation}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {employee.department}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {employee.cnic}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {employee.phone}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {employee.salary}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor: "#1976D2",
                          margin: 2,
                        }}
                        aria-label="view"
                        size="small"
                        onClick={() =>
                          profile(employee._id, employee.userId._id)
                        }
                      >
                        <VisibilityIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor: "#4CAF50",
                          margin: 2,
                        }}
                        aria-label="edit"
                        size="small"
                        onClick={() => selectEmployee(employee._id)}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor: "#F44336",
                          margin: 2,
                        }}
                        aria-label="delete"
                        size="small"
                        onClick={() => handleClickOpen(employee._id)}
                      >
                        {employee.status === "active" ? (
                          <ArchiveIcon fontSize="inherit" />
                        ) : (
                          <UnarchiveIcon fontSize="inherit" />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Modal open={openFilter} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              right: "0",
              transform: "translate(-20%, -50%)",
              width: 400,
              height: "70vh",
              bgcolor: "#2d2d2b",
              color: "#fff",
              borderRadius: "10px",
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h6">Filter</Typography>
                <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
              </Box>
              <Divider sx={{ my: 2, bgcolor: "#fff" }} />
              <Accordion
                sx={{
                  bgcolor: "#2d2d2b",
                  color: "#fff",
                  boxShadow: "none",
                  "& .MuiAccordionSummary-root": {
                    bgcolor: "#2d2d2b",
                    color: "#fff",
                  },
                  "& .MuiAccordionDetails-root": {
                    bgcolor: "#2d2d2b",
                    color: "#fff",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                >
                  <Typography>Employee Status</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="employee-status"
                      name="employee-status"
                      value={statusFilter}
                      onChange={handleStatusChange}
                    >
                      {statuses.map((status) => (
                        <FormControlLabel
                          key={status.value}
                          value={status.value}
                          control={
                            <Radio
                              sx={{
                                color: "#fff",
                                "&.Mui-checked": {
                                  color: "#FF5722",
                                },
                              }}
                            />
                          }
                          label={status.label}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  bgcolor: "#2d2d2b",
                  color: "#fff",
                  boxShadow: "none",
                  "& .MuiAccordionSummary-root": {
                    bgcolor: "#2d2d2b",
                    color: "#fff",
                  },
                  "& .MuiAccordionDetails-root": {
                    bgcolor: "#2d2d2b",
                    color: "#fff",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                >
                  <Typography>Salary Range</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ bgcolor: "#2d2d2b", color: "#fff", p: 2 }}>
                    <Slider
                      value={salaryRange}
                      onChange={handleSliderChange}
                      valueLabelDisplay="auto"
                      min={10000}
                      max={500000}
                      step={5000}
                      getAriaValueText={formatLabel}
                      sx={{ color: "#FF5722" }}
                    />
                    <Typography color="#fff" variant="body1" mt={2}>
                      <b>Selected Range:</b>
                      {` ${formatLabel(salaryRange[0])} - ${formatLabel(
                        salaryRange[1]
                      )}`}
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box>
              <Divider sx={{ my: 2, bgcolor: "#fff" }} />
              <Button
                variant="outlined"
                color="error"
                fullWidth
                onClick={() => {
                  setStatusFilter("");
                  setSalaryRange([10000, 500000]);
                }}
              >
                Clear Filters
              </Button>
            </Box>
          </Box>
        </Modal>

        <Dialog
          open={open}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#212121",
              color: "#fff",
              padding: "15px",
            },
            "& .MuiDialogTitle-root": {
              padding: "5px",
            },
            "& .MuiDialogContentText-root": {
              color: "#fff",
            },
            "& .MuiDialogContent-root": {
              padding: "5px",
            },
            "& .MuiDialogActions-root": {
              padding: "5px",
            },
          }}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this employee?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                backgroundColor: "#A6A6A6",
                color: "#fff",
                "&:hover": { backgroundColor: "#B8B8B8" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              sx={{
                backgroundColor: "#E5622D",
                color: "#fff",
                "&:hover": { backgroundColor: "#D0521B" },
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <AddEmployee
          open={openModal}
          handleClose={handleClose}
          employeeId={updateId}
        />
      </div>
    </div>
  );
};

export default Employees;
