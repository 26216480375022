import React, { useState, useEffect } from "react";
import { getRequest, delRequest } from "../../axiosRoutes/index";
import {
  Button,
  Box,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import SideNav from "../sidebar/SideNav";
import EditIcon from "@mui/icons-material/Edit";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../axiosRoutes/toastConfig";
import AddInventory from "./AddInventory";
import AssignItem from "./AssignItem";

const Inventory = () => {
  const [inventories, setInventories] = useState([]);
  const [assignData, setAssignData] = useState([]);
  const [unassignData, setUnassignData] = useState({
    userId: "",
  });
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);
  const [openUnassign, setOpenUnassign] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [updateId, setUpdateId] = useState(null);
  const [itemId, setItemId] = useState(null);

  const fetchInventories = async () => {
    const access = sessionStorage.getItem("access");
    try {
      const response = await getRequest("admin/inventory", {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      setInventories(response);
    } catch (error) {
      console.error("Error Fetching Inventories:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInventories();
  }, []);

  const handleDelete = async () => {
    try {
      const access = sessionStorage.getItem("access");
      const response = await delRequest(`admin/inventory/${deleteId}`, access);
      fetchInventories();
      handleClose();
      toast.success(response, toastConfig);
    } catch (error) {
      toast.error(error.message, toastConfig);
    }
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleAssignOpen = () => {
    setOpenAssign(true);
  };

  const handleUnassignOpen = (id) => {
    setItemId(id);
    setOpenUnassign(true);
  };

  const handleClickOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const selectProject = (id) => {
    setUpdateId(id);
    handleOpen();
  };

  const handleAssign = (id) => {
    setItemId(id);
    handleAssignOpen();
  };

  const handleSelectChange = (event) => {
    const selectedUserId = event.target.value;
    setUnassignData((prevState) => ({
      ...prevState,
      userId: selectedUserId,
    }));
  };

  const handleUnassign = async () => {
    try {
      const access = sessionStorage.getItem("access");
      const response = await delRequest(
        `admin/inventory/${itemId}/assignTo`,
        access,
        unassignData
      );
      fetchInventories();
      handleClose();
      toast.success(response, toastConfig);
    } catch (error) {
      if (!error.response) {
        toast.error(error.message, toastConfig);
      } else if (error.response) {
        toast.error(error.response.data.message, toastConfig);
      }
    }
  };

  useEffect(() => {
    if (itemId !== null) {
      const fetchAssign = async () => {
        const access = sessionStorage.getItem("access");
        try {
          const res = await getRequest(`admin/inventory/${itemId}`, {
            headers: {
              Authorization: `Bearer ${access}`,
            },
          });
          const assignedData = res.assignedTo.map((user) => ({
            userId: user.userId,
            name: user.name,
          }));
          setAssignData(assignedData);
        } catch (error) {
          console.error("Error fetching Item:", error);
        }
      };
      fetchAssign();
    }
  }, [itemId]);

  const handleClose = () => {
    setOpenModal(false);
    setOpen(false);
    setOpenAssign(false);
    setOpenUnassign(false);
    fetchInventories();
    setItemId(null);
    setAssignData([]);
    setDeleteId(null);
    setUpdateId(null);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FF5722" }} />
        <Typography variant="h6" sx={{ marginTop: "18px", color: "#fff" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <div className="app-container" style={{ color: "#fff", display: "flex" }}>
      <ToastContainer />
      <SideNav />
      <div style={{ flex: 1, margin: "0.5rem" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          padding="1rem"
        >
          <Grid item>
            <h1>Office Inventory</h1>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{
                backgroundColor: "#fff",
                color: "black",
                "&:hover": {
                  backgroundColor: "#e1e1e1",
                },
              }}
            >
              Add Items
            </Button>
          </Grid>
        </Grid>
        {inventories.length === 0 ? (
          <Typography variant="h6" sx={{ color: "#fff", textAlign: "center" }}>
            No Data Yet
          </Typography>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Title
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Condition
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Price
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Properties ID
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Properties List
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Remaining Quantity
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Assigned To
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inventories.map((inventory, id) => (
                  <TableRow key={id}>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {id + 1}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {inventory.title}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {inventory.condition}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {inventory.price}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                        maxWidth: "200px",
                      }}
                    >
                      {inventory.propertiesList.map(
                        (property) => `${property.id}`
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                        maxWidth: "200px",
                      }}
                    >
                      {inventory.propertiesList.map(
                        (property) => `${property.properties}, `
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {inventory.quantity}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {inventory.quantity -
                        inventory.assignedTo.reduce(
                          (total, assign) => total + assign.quantity,
                          0
                        )}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {inventory.assignedTo.map((assign, keys) => (
                        <li key={keys}>{assign.name}</li>
                      ))}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      <Tooltip title="Assign" placement="top">
                        <IconButton
                          style={{
                            color: "#fff",
                            backgroundColor: "#1976D2",
                            margin: 2,
                          }}
                          aria-label="edit"
                          size="small"
                          onClick={() => handleAssign(inventory._id)}
                        >
                          <InventoryOutlinedIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="UnAssign" placement="top">
                        <IconButton
                          style={{
                            color: "#fff",
                            backgroundColor: "#F44336",
                            margin: 2,
                          }}
                          aria-label="edit"
                          size="small"
                          onClick={() => handleUnassignOpen(inventory._id)}
                        >
                          <DeleteSweepIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor: "#4CAF50",
                          margin: 2,
                        }}
                        aria-label="edit"
                        size="small"
                        onClick={() => selectProject(inventory._id)}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor: "#F44336",
                          margin: 2,
                        }}
                        aria-label="delete"
                        size="small"
                        onClick={() => handleClickOpen(inventory._id)}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Dialog
          open={open}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#212121",
              color: "#fff",
              padding: "15px",
            },
            "& .MuiDialogTitle-root": {
              padding: "5px",
            },
            "& .MuiDialogContentText-root": {
              color: "#fff",
            },
            "& .MuiDialogContent-root": {
              padding: "5px",
            },
            "& .MuiDialogActions-root": {
              padding: "5px",
            },
          }}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are You Sure You Want to Delete this Item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                backgroundColor: "#A6A6A6",
                color: "#fff",
                "&:hover": { backgroundColor: "#B8B8B8" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              sx={{
                backgroundColor: "#E5622D",
                color: "#fff",
                "&:hover": { backgroundColor: "#D0521B" },
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          maxWidth="sm"
          open={openUnassign}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#212121",
              color: "#fff",
              padding: "20px",
              borderRadius: "8px",
            },
            "& .MuiDialogTitle-root": {
              padding: "10px 5px",
              borderBottom: "1px solid #E5622D",
            },
            "& .MuiDialogContent-root": {
              padding: "10px",
            },
            "& .MuiDialogActions-root": {
              padding: "10px 5px",
              justifyContent: "space-around",
            },
          }}
        >
          <DialogTitle
            sx={{
              typography: "h6",
              marginBottom: "10px",
              fontWeight: "bold",
              color: "#E5622D",
            }}
          >
            Confirm UnAssign Item
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ marginBottom: "20px", color: "#fff" }}>
              Are you sure you want to unassign this item from the selected
              employee?
            </DialogContentText>
            {assignData.length === 0 ? (
              <Typography
                variant="h6"
                sx={{ color: "#fff", textAlign: "center", margin: "10px 0" }}
              >
                No Item Assigned Yet
              </Typography>
            ) : (
              <FormControl
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    color: "#FEFEFE",
                    "& fieldset": {
                      borderColor: "#FEFEFE",
                    },
                    "&:hover fieldset": {
                      borderColor: "#FEFEFE",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#FEFEFE",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#FEFEFE",
                  },
                }}
              >
                <InputLabel id="client-select-label">Employee Name</InputLabel>
                <Select
                  labelId="client-select-label"
                  value={unassignData.userId}
                  onChange={handleSelectChange}
                  label="Employee Name"
                >
                  {assignData.map((assign) => (
                    <MenuItem key={assign.userId} value={assign.userId}>
                      {assign.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                backgroundColor: "#A6A6A6",
                color: "#fff",
                "&:hover": { backgroundColor: "#B8B8B8" },
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={assignData.length === 0}
              onClick={handleUnassign}
              sx={{
                backgroundColor: "#E5622D",
                color: "#fff",
                "&:hover": { backgroundColor: "#D0521B" },
                "&.Mui-disabled": {
                  backgroundColor: "#EC916C",
                  color: "#fff",
                },
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <AddInventory
          open={openModal}
          handleClose={handleClose}
          inventoryId={updateId}
        />
        <AssignItem
          open={openAssign}
          handleClose={handleClose}
          itemId={itemId}
        />
      </div>
    </div>
  );
};

export default Inventory;
