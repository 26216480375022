/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { getRequest, delRequest, putRequest } from "../../../axiosRoutes/index";
import {
  Button,
  Box,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SideNavBar from "../../sidebar/SideNav";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "react-toastify/dist/ReactToastify.css";
import { toastConfig } from "../../../axiosRoutes/toastConfig";
import AddFitness from "./AddFitness";

const getCurrentMonthYear = () => {
  const now = new Date();
  const month = now.getMonth() + 1;
  const year = now.getFullYear();
  return `${month}/${year}`;
};

const EmployeeFitness = () => {
  const [statuses, setStatuses] = useState([]);
  const [fitnessAllowances, setFitnessAllowances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [action, setAction] = useState("");
  const [comments, setComments] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [updateId, setUpdateId] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthYear());

  const handleMonthChange = (date) => {
    if (date) {
      const formattedDate = dayjs(date).format("MM/YYYY");
      setSelectedMonth(formattedDate);
      fetchFitness(formattedDate);
    }
  };

  const fetchStatus = async () => {
    try {
      const response = await getRequest("filter/status");
      const statusArray = Object.keys(response).map((key) => ({
        label: key,
        value: response[key],
      }));
      setStatuses(statusArray);
    } catch (error) {
      console.error("Error fetching Filter:", error);
      toast.error("Error fetching filter statuses", toastConfig);
    }
  };

  const fetchFitness = async (month) => {
    const access = sessionStorage.getItem("access");
    setLoading(true);
    try {
      const response = await getRequest(`admin/fitnessAllowance`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
        params: { month },
      });

      const formattedFitness = response.map((fitness) => {
        if (fitness.date) {
          const date = new Date(fitness.date);
          fitness.date = date.toLocaleDateString("en-GB");
        }
        const statusObj = statuses.find(
          (status) => status.value === fitness.status
        );
        fitness.status = statusObj ? statusObj.label : fitness.status;
        return fitness;
      });
      setFitnessAllowances(formattedFitness);
    } catch (error) {
      console.error("Error fetching Fitness Allowances:", error);
      toast.error("Error fetching fitness allowances", toastConfig);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  useEffect(() => {
    if (statuses.length > 0) {
      fetchFitness(selectedMonth);
    }
  }, [statuses]);

  useEffect(() => {
    fetchFitness(selectedMonth);
  }, []);

  const handleDelete = async () => {
    try {
      const access = sessionStorage.getItem("access");
      await delRequest(`admin/fitnessAllowance/${deleteId}`, access);
      handleClose();
      toast.success("Fitness Allowance deleted successfully", toastConfig);
      fetchFitness(selectedMonth);
    } catch (error) {
      toast.error(error.message, toastConfig);
    }
  };

  const handleStatusOpen = (id, newStatus) => {
    setUpdateId(id);
    setAction(newStatus);
    setOpenStatus(true);
  };

  const handleFitnessAction = async () => {
    try {
      const access = sessionStorage.getItem("access");
      await putRequest(
        `admin/fitnessAllowance/${updateId}`,
        {
          Authorization: `Bearer ${access}`,
        },
        {
          status: action,
          comments: comments,
        }
      );
      fetchFitness(selectedMonth);
      handleClose();
      toast.success(
        `Fitness Allowance ${
          action === "approved" ? "Approved" : "Rejected"
        } Successfully`,
        toastConfig
      );
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, toastConfig);
    }
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClickOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const selectFitness = (id) => {
    setUpdateId(id);
    handleOpen();
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpen(false);
    setOpenStatus(false);
    setDeleteId(null);
    setUpdateId(null);
    setAction("");
    setComments("");
    fetchFitness(selectedMonth);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FF5722" }} />
        <Typography variant="h6" sx={{ marginTop: "18px", color: "#fff" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <div className="app-container" style={{ color: "#fff", display: "flex" }}>
      <ToastContainer />
      <SideNavBar />
      <div style={{ flex: 1, margin: "0.5rem" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          padding="1rem"
        >
          <Grid item>
            <h1>Employees Fitness Allowance</h1>
          </Grid>
          <Grid
            item
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item mr={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select Month"
                  views={["month", "year"]}
                  onChange={handleMonthChange}
                  inputFormat="MM/YYYY"
                  sx={{
                    "& .MuiInputBase-root": {
                      color: "#fff",
                      borderColor: "#FF5722",
                      "& fieldset": {
                        borderColor: "#FF5722",
                      },
                      "&:hover fieldset": {
                        borderColor: "#FF5722",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#FF5722",
                      },
                    },
                    "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                      color: "#fff",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#fff",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={handleOpen}
                sx={{
                  backgroundColor: "#fff",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#e1e1e1",
                  },
                }}
              >
                Add Fitness Allowance
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {fitnessAllowances.length === 0 ? (
          <Typography variant="h6" sx={{ color: "#fff", textAlign: "center" }}>
            No Data Yet
          </Typography>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Total Amount
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Amount Reimbursed
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Comments
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      border: "1px solid #fff",
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fitnessAllowances.map((fitnessAllowance, id) => (
                  <TableRow key={id}>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {id + 1}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {fitnessAllowance.name}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {fitnessAllowance.totalAmount}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {fitnessAllowance.amountReimbursed}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {fitnessAllowance.comments || "N/A"}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {fitnessAllowance.date}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {fitnessAllowance.status}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        border: "1px solid #fff",
                      }}
                    >
                      {fitnessAllowance.status === "Pending" && (
                        <>
                          <IconButton
                            style={{
                              color: "#fff",
                              backgroundColor: "#4CAF50",
                              margin: 2,
                            }}
                            aria-label="approve"
                            size="small"
                            onClick={() =>
                              handleStatusOpen(fitnessAllowance._id, "approved")
                            }
                          >
                            <DoneIcon fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            style={{
                              color: "#fff",
                              backgroundColor: "#f44336",
                              margin: 2,
                            }}
                            aria-label="reject"
                            size="small"
                            onClick={() =>
                              handleStatusOpen(fitnessAllowance._id, "rejected")
                            }
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        </>
                      )}

                      {fitnessAllowance.status === "Approved" && (
                        <IconButton
                          style={{
                            color: "#fff",
                            backgroundColor: "#f44336",
                            margin: 2,
                          }}
                          aria-label="reject"
                          size="small"
                          onClick={() =>
                            handleStatusOpen(fitnessAllowance._id, "rejected")
                          }
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      )}

                      {fitnessAllowance.status === "Rejected" && (
                        <IconButton
                          style={{
                            color: "#fff",
                            backgroundColor: "#4CAF50",
                            margin: 2,
                          }}
                          aria-label="approve"
                          size="small"
                          onClick={() =>
                            handleStatusOpen(fitnessAllowance._id, "approved")
                          }
                        >
                          <DoneIcon fontSize="inherit" />
                        </IconButton>
                      )}
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor: "#1976D2",
                          margin: 2,
                        }}
                        aria-label="edit"
                        size="small"
                        onClick={() => selectFitness(fitnessAllowance._id)}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        style={{
                          color: "#fff",
                          backgroundColor: "#F44336",
                          margin: 2,
                        }}
                        aria-label="delete"
                        size="small"
                        onClick={() => handleClickOpen(fitnessAllowance._id)}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Dialog
          open={open}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#212121",
              color: "#fff",
              padding: "15px",
            },
            "& .MuiDialogTitle-root": {
              padding: "5px",
            },
            "& .MuiDialogContentText-root": {
              color: "#fff",
            },
            "& .MuiDialogContent-root": {
              padding: "5px",
            },
            "& .MuiDialogActions-root": {
              padding: "5px",
            },
          }}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this Fitness Allowance?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                backgroundColor: "#A6A6A6",
                color: "#fff",
                "&:hover": { backgroundColor: "#B8B8B8" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              sx={{
                backgroundColor: "#E5622D",
                color: "#fff",
                "&:hover": { backgroundColor: "#D0521B" },
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          maxWidth="sm"
          open={openStatus}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#212121",
              color: "#fff",
              padding: "20px",
              borderRadius: "8px",
            },
            "& .MuiDialogTitle-root": {
              padding: "10px 5px",
              borderBottom: "1px solid #E5622D",
            },
            "& .MuiDialogContent-root": {
              padding: "10px",
            },
            "& .MuiDialogActions-root": {
              padding: "10px 5px",
              justifyContent: "space-around",
            },
          }}
        >
          <DialogTitle
            sx={{
              typography: "h6",
              marginBottom: "10px",
              fontWeight: "bold",
              color: "#E5622D",
            }}
          >
            Confirm Fitness Allowance Status Update
          </DialogTitle>
          <DialogContent>
            <FormControl
              variant="outlined"
              fullWidth
              sx={{
                mb: 1,
                mt: 1,
                "& .MuiOutlinedInput-root": {
                  color: "#FEFEFE",
                  "& fieldset": {
                    borderColor: "#FEFEFE",
                  },
                  "&:hover fieldset": {
                    borderColor: "#FEFEFE",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#FEFEFE",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#FEFEFE",
                },
              }}
            >
              <InputLabel htmlFor="outlined-adornment-Comments">
                Comments
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-comments"
                type="text"
                name="comments"
                label="Comments"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                sx={{ backgroundColor: "transparent" }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                backgroundColor: "#A6A6A6",
                color: "#fff",
                "&:hover": { backgroundColor: "#B8B8B8" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleFitnessAction}
              sx={{
                backgroundColor: "#E5622D",
                color: "#fff",
                "&:hover": { backgroundColor: "#D0521B" },
                "&.Mui-disabled": {
                  backgroundColor: "#EC916C",
                  color: "#fff",
                },
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <AddFitness
          open={openModal}
          handleClose={handleClose}
          fitnessId={updateId}
        />
      </div>
    </div>
  );
};

export default EmployeeFitness;
